import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
import "./success-page.scss";
import claypooleLogo from "../../images/common/entryLogo2.png";

const SuccessPage = ({ text = false, isSubmitted = false }) => {
  const loadingContainerRef = useRef(null);

  gsap.set(loadingContainerRef.current, {y: "140%", opacity: 0})
  useEffect(() => {

    if (isSubmitted) {
      const tl = gsap.timeline();

      tl.to(loadingContainerRef.current, {
        y: "0",
        duration: 2,
        delay: 0,
        ease: "power4.out",
        opacity: 1,
      });
      tl.to(loadingContainerRef.current, {
        y: "-140%",
        duration: 1.5,
        delay: 0,
        ease: "power4.out",
        opacity: 0.5,
      });
    }
  }, [isSubmitted]);

  return (
    <div className="entry-page" ref={loadingContainerRef}>
      <div className="entry-page__image">
        <StaticImage
          src="../../images/common/entryPage2.png"
          alt="Background Image"
          layout="fullWidth"
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          loading="eager"
        />
      </div>
      <div style={{ zIndex: 2 }} className="entry-page__logo">
        <div className="entry-page__logo__inner">
          <div className="entry-page__logo__image-wrapper">
            <img src={claypooleLogo} alt="claypoole logo" loading="eager" />
          </div>

          <span></span>
          <div className="entry-page__logo__text">
            {text && <h2>{text}</h2>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
