import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import { TextBox } from "../components/form/fields";
import { Link } from "../components/links";
import "../styles/pages/survey-results.scss";
import { legal } from "../data/pages/survey-results";
import CTA from "../components/cta";
import { fields, useFieldErrors } from "../components/form/validations";
import gsap from "gsap";
import SurveyResultsSection from "../components/SurveyResults";
import SuccessPage from "../components/SuccessPage";

const SurveyResults = () => {
  const [hasAcceptedPassword, setHasAcceptedPassword] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [hasAgreedToPrivacy, setHasAgreedToPrivacy] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formElementsDisabled, setFormElementsDisabled] = useState(true);
  const formRef = useRef(null);
  const legalRef = useRef(null);
  const [formData, setFormData] = useState({
    password: "",
  });
  const [errors, setFieldErrors, touched] = useFieldErrors(fields, formData);
  const password =
    process.env.NODE_ENV === "production"
      ? process.env.GATSBY_SURVEY_RESULTS_PASSWORD
      : process.env.GATSBY_LOCAL_SURVEY_RESULTS_PASSWORD;

  const tempPassword =
    process.env.NODE_ENV === "production"
      ? process.env.GATSBY_SURVEY_RESULTS_TEMP_PASSWORD
      : process.env.GATSBY_LOCAL_SURVEY_RESULT_TEMP_PASSWORD;

  const hasErrors = () => {
    console.log("erasd12:", errors.password);
    if (errors.password && errors.password.length > 0) {
      console.log("erasd:", errors.password);
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted && hasAcceptedPassword) {
      return;
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    fields.forEach((field) => {
      setFieldErrors(field, formData[field.id] || "", true);
    });

    const field = fields.find((f) => f.id === name);
    if (field) {
      setFieldErrors(field, value, true);
    }

    if (hasErrors()) {
      console.log("Invalid password");
      return;
    }
    console.log("Form submitting...");
    const hashedUserInput = CryptoJS.SHA256(formData.password).toString(
      CryptoJS.enc.Hex
    );
    console.log("hashedUserInput:", hashedUserInput);
    if (hashedUserInput === password || hashedUserInput === tempPassword) {
      setHasAcceptedPassword(true);
      setIsSubmitted(true);
    }
  };

  const handleTermsChange = (event) => {
    setHasAgreedToTerms(event.target.checked);
  };
  const handlePrivacyChange = (event) => {
    setHasAgreedToPrivacy(event.target.checked);
  };

  useEffect(() => {
    if (hasAgreedToTerms === true && hasAgreedToPrivacy === true) {
      setFormElementsDisabled(false);
    } else {
      setFormElementsDisabled(true);
    }
  }, [hasAgreedToTerms, hasAgreedToPrivacy, errors.password]);

  useEffect(() => {
    const tl = gsap.timeline();
    const tl2 = gsap.timeline();
    if (hasAcceptedPassword) {
      tl.to(formRef.current, {y: 60, opacity: 0, duration: 1});
      tl.to(formRef.current, {display: "none"});
      tl2.to(legalRef.current, {y: 60, opacity: 0, duration: 1});
      tl2.to(legalRef.current, {display: "none"});
      window.scrollTo(0, 0);
    }
  }, [hasAcceptedPassword]);

  return (
    <div className="survey-results">
      <SuccessPage isSubmitted={isSubmitted} text={"The results are in!"} />
      <div className="survey-results__inner">
        <SurveyResultsSection display={hasAcceptedPassword} />
        <div ref={legalRef} className="survey-results__legal">
          <h1 className="survey-results__header">
            Compensation Survey Results
          </h1>
          <h3>Please read carefully.</h3>
          {legal.map((text) => (
            <p key={text}>{text}</p>
          ))}
        </div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="survey-results__terms">
            <div>
              <input
                type="checkbox"
                checked={hasAgreedToTerms}
                onChange={handleTermsChange}
              />
              <p>Accepted and agreed.</p>
            </div>
            <div>
              <input
                type="checkbox"
                checked={hasAgreedToPrivacy}
                onChange={handlePrivacyChange}
              />
              <p>
                I agree to the{" "}
                <Link to="/privacy-policy">Claypoole Privacy Policy</Link>
              </p>
            </div>
          </div>
          <TextBox
            type="text"
            name="password"
            value={formData.password || ""}
            placeholder="Enter your provided password"
            parentErrors={touched.password ? errors.password : []}
            onChange={handleChange}
            disabled={formElementsDisabled}
          />
          <CTA
            disable={formElementsDisabled}
            submitted={isSubmitted}
            button={true}
          >
            Submit
          </CTA>
        </form>
      </div>
    </div>
  );
};

export default SurveyResults;
