import React from "react";
import classNames from "classnames";
import gsap from "gsap";
import "./text-box.scss";

const TextBox = ({
  name,
  value,
  onChange = {},
  placeholder,
  errors,
  type,
  textArea = false,
  parentErrors,
  disabled = false,
}) => {
  const displayErrors = errors ? errors : parentErrors;

  const textBoxClasses = classNames("db-text-box", {
    "db-text-box--disabled": disabled,
  });
  return (
    <div className={textBoxClasses}>
      <div className="db-text-box__box">
        {textArea === false && (
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
        {textArea && (
          <textarea
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        )}
      </div>
      <ul className="db-text-box__errors">
        {displayErrors &&
          displayErrors.map((error) => (
            <li key={error} className="db-text-box__errors__error">
              {error}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TextBox;
