import React, { useRef, useEffect } from "react";
import "./survey-results.scss";
import { Link } from "../links";
import gsap from "gsap";
import classNames from "classnames";

const SurveyResultsSection = ({ display }) => {
  const surveyRef = useRef(null);

  useEffect(() => {
    const surveyElement = surveyRef.current;
    if (surveyElement) {
      const preventDefaultAction = (event) => event.preventDefault();

      surveyElement.addEventListener("contextmenu", preventDefaultAction);
      surveyElement.addEventListener("copy", preventDefaultAction);
      surveyElement.addEventListener("cut", preventDefaultAction);

      // Clean up the event listeners when the component unmounts or rerenders
      return () => {
        surveyElement.removeEventListener("contextmenu", preventDefaultAction);
        surveyElement.removeEventListener("copy", preventDefaultAction);
        surveyElement.removeEventListener("cut", preventDefaultAction);
      };
    }
  }, []);

  useEffect(() => {
    const surveyChildren = [...surveyRef.current.children];
    surveyChildren.forEach((section) => {
      gsap.set(section, {
        y: 400,
        opacity: 0,
      });
    });
    if (display === true) {
      surveyChildren.forEach((section, index) => {
        gsap.to(section, {
          y: 0,
          duration: 2.2,
          ease: "power4.out",
          opacity: 1,
          delay: 1.5 + index * 0.04,
        });
      });
    }
  }, [display]);

  const resultClasses = classNames("survey-results-section", {
    "survey-results-section--display": display,
  });

  return (
    <div ref={surveyRef} className={resultClasses}>
      <div className="survey-results-section__watermark">
        <h1>Document Subect to NDA</h1>
        <h1>Do Not Print or Circulate</h1>
      </div>
      <div className="survey-results-section__print-warning">
        <div>
          <h1>WARNING:</h1>
          <h3>You are attempting to violate a non-disclosure agreement.</h3>
          <p>
            "At any time, upon the request of Claypoole, you agree to return
            promptly to Claypoole Search all Confidential Information which may be in
            your possession or control. If certain Confidential Information
            cannot be returned, you agree to destroy it and to certify such
            destruction. If certain Confidential Information cannot be returned
            or destroyed, you agree that it will remain subject to the
            confidentiality provisions of this letter agreement."
          </p>
        </div>
      </div>
      <h1 className="survey-results-section__header sensitive">
        COMPENSATION OF LEGAL AND COMPLIANCE PROFESSIONALS AT ALTERNATIVE ASSET
        MANAGERS 2023 (2022 DATA SET)
      </h1>
      <div className="survey-results-section__toc sensitive">
        <h2>Table of Contents</h2>
        <div className="survey-results-section__toc__index">
          <Link to="/survey-results#introduction" isAnchor>
            Introduction
          </Link>
          <Link to="/survey-results#summary" isAnchor>
            Summary
          </Link>
          <Link to="/survey-results#general-counsel" isAnchor>
            General Counsel
          </Link>
          <Link to="/survey-results#associate-general-counsel" isAnchor>
            Associate General Counsel
          </Link>
          <Link to="/survey-results#chief-compliance-officer" isAnchor>
            Chief Compliance Officer
          </Link>
        </div>
      </div>
      <div
        id="introduction"
        className="survey-results-section__introduction sensitive"
      >
        <h2>Introduction</h2>
        <p>
          For nearly two decades, we have been conducting a survey on
          compensation levels for legal and compliance professionals at
          alternative managers. Until recently, we collected most of the data
          through phone interviews. However, due to increased participation, we
          transitioned to an online form on our secure website.
        </p>
        <p>
          Over the years, legal departments and compensation structures have
          undergone significant changes. We took this opportunity to expand the
          scope of the survey to include additional variables, such as years in
          BigLaw, strategy, department size and vesting periods. In the spring,
          we will publish an analysis examining the relationship between
          compensation and these new variables.
        </p>
        <p>
          This study provides ranges, medians, quartiles and deciles for total
          compensation (base salary + cash bonus + deferred compensation +
          equity) earned in 2022 across a variety of variables. Participants
          include General Counsel, Associate General Counsel and Chief
          Compliance Officers of alternative asset managers, including hedge
          funds, fund of funds, private equity funds, venture funds, family
          offices and the alternative asset management divisions of major asset
          managers and financial institutions. Participants work in major
          markets, including the New York Tri-State Area, Boston, Chicago,
          Washington DC, Northern/Southern California and London. The majority
          of data comes from professionals in NYC.
        </p>
        <p>
          It's important to note that participation in the survey is voluntary,
          which may skew the results towards the lower end of the compensation
          spectrum. We know of many highly paid professionals who would fall
          into (or above) the top decile and do not participate. Therefore, the
          upper ranges of industry compensation levels are likely higher than
          reported here.
        </p>
        <p>
          <strong>
            THIS DOCUMENT HAS ALWAYS BEEN PROVIDED FREE OF CHARGE TO THOSE WHO
            CONTRIBUTE DATA, AND PARTICIPANTS HAVE BEEN ASKED NOT TO SHARE THE
            SURVEY WITH NON-CONTRIBUTERS, THE IDEA BEING THAT THE MORE DATA WE
            CAN GATHER, THE MORE USEFUL THE SURVEY WILL BE. TO DISCOURAGE
            SHARING, THE SURVEY IS BEING CIRCULATED ON A SECURE WEBSITE.
            REQUESTS FOR HARD COPIES WILL BE EVALUATED INDIVIDUALLY.
          </strong>
        </p>
      </div>
      <div id="summary" className="survey-results-section__summary sensitive">
        <h2>Summary - All Job Categories</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All GC</p>
                <p>GC</p>
                <p>GC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>154</li>
                  <li>59</li>
                  <li>95</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$285,000</li>
                  <li>$360,250</li>
                  <li>$285,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$500,000</li>
                  <li>$590,000</li>
                  <li>$470,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$712,500</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$915,000</li>
                  <li>$1,000,000</li>
                  <li>$890,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,450,000</li>
                  <li>$1,425,000</li>
                  <li>$1,325,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,000,000</li>
                  <li>$2,025,000</li>
                  <li>$1,900,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$4,450,000</li>
                  <li>$4,700,000</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>ALL AGC</p>
                <p>AGC</p>
                <p>AGC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>110</li>
                  <li>85</li>
                  <li>25</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$125,000</li>
                  <li>$125,000</li>
                  <li>$260,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$300,000</li>
                  <li>$300,000</li>
                  <li>$385,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$420,000</li>
                  <li>$400,000</li>
                  <li>$570,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$580,000</li>
                  <li>$565,000</li>
                  <li>$700,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$725,000</li>
                  <li>$685,000</li>
                  <li>$800,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,100,000</li>
                  <li>$995,000</li>
                  <li>$1,500,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,875,000</li>
                  <li>$2,875,000</li>
                  <li>$1,800,000</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>All CCO</p>
                <p>CCO</p>
                <p>AGC/CCO</p>
                <p>GC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>166</li>
                  <li>46</li>
                  <li>25</li>
                  <li>95</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$170,000</li>
                  <li>$170,000</li>
                  <li>$260,000</li>
                  <li>$285,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$377,500</li>
                  <li>$310,000</li>
                  <li>$385,000</li>
                  <li>$470,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$526,500</li>
                  <li>$385,000</li>
                  <li>$570,000</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$757,500</li>
                  <li>$605,000</li>
                  <li>$700,000</li>
                  <li>$890,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,200,000</li>
                  <li>$1,011,000</li>
                  <li>$810,000</li>
                  <li>$1,325,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,700,000</li>
                  <li>$1,300,000</li>
                  <li>$1,500,000</li>
                  <li>$1,900,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$3,900,000</li>
                  <li>$1,800,000</li>
                  <li>$4,700,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="general-counsel"
        className="survey-results-section__summary sensitive"
      >
        <h2>General Counsel</h2>
        <table>
          <thead>
            <tr>
              <th>
                <strong>Entire Grouping</strong>
              </th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All GC</p>
                <p>GC</p>
                <p>GC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>154</li>
                  <li>59</li>
                  <li>95</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$285,000</li>
                  <li>$360,250</li>
                  <li>$285,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$500,000</li>
                  <li>$590,000</li>
                  <li>$470,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$712,500</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$915,000</li>
                  <li>$1,000,000</li>
                  <li>$890,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,450,000</li>
                  <li>$1,425,000</li>
                  <li>$1,325,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,000,000</li>
                  <li>$2,025,000</li>
                  <li>$1,900,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$4,450,000</li>
                  <li>$4,700,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Experience</strong>
              </th>
              <th>Sample</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>16 Years or More</p>
                <p>15 Years or Less</p>
              </td>
              <td>
                <ul>
                  <li>120</li>
                  <li>34</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>46</li>
                  <li>13</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>74</li>
                  <li>21</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$340,000</li>
                  <li>$285,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$525,000</li>
                  <li>$440,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$708,250</li>
                  <li>$545,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$980,000</li>
                  <li>$750,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,500,000</li>
                  <li>$1,075,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,155,000</li>
                  <li>$1,572,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$2,000,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Assets</strong>
              </th>
              <th>Sample</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>$20Bn and Over</p>
                <p>$5Bn to {"<"} $20Bn</p>
                <p>Under $5Bn</p>
              </td>
              <td>
                <ul>
                  <li>21</li>
                  <li>48</li>
                  <li>85</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>13</li>
                  <li>18</li>
                  <li>28</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>8</li>
                  <li>30</li>
                  <li>57</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$455,000</li>
                  <li>$340,000</li>
                  <li>$285,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$520,000</li>
                  <li>$635,000</li>
                  <li>$485,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$635,000</li>
                  <li>$910,000</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$975,000</li>
                  <li>$1,262,500</li>
                  <li>$800,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,500,000</li>
                  <li>$1,700,000</li>
                  <li>$1,025,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,100,000</li>
                  <li>$2,350,000</li>
                  <li>$1,805,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$3,000,000</li>
                  <li>$4,700,000</li>
                  <li>$4,450,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Type</strong>
              </th>
              <th>Sample</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Hedge</p>
                <p>PE/Venture</p>
                <p>Blended</p>
              </td>
              <td>
                <ul>
                  <li>77</li>
                  <li>29</li>
                  <li>48</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>27</li>
                  <li>8</li>
                  <li>24</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>50</li>
                  <li>21</li>
                  <li>24</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$285,000</li>
                  <li>$360,000</li>
                  <li>$345,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$510,000</li>
                  <li>$445,000</li>
                  <li>$515,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$600,000</li>
                  <li>$750,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$890,000</li>
                  <li>$1,000,000</li>
                  <li>$1,000,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,250,000</li>
                  <li>$1,625,000</li>
                  <li>$1,400,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,720,000</li>
                  <li>$2,450,000</li>
                  <li>$2,150,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,450,000</li>
                  <li>$4,700,000</li>
                  <li>$3,750,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Location</strong>
              </th>
              <th>Sample</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>NY Metro/CA</p>
                <p>Elsewhere</p>
              </td>
              <td>
                <ul>
                  <li>127</li>
                  <li>27</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>51</li>
                  <li>8</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>76</li>
                  <li>19</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$285,000</li>
                  <li>$340,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$500,000</li>
                  <li>$448,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$657,500</li>
                  <li>$565,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$950,000</li>
                  <li>$834,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,475,000</li>
                  <li>$1,090,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,020,000</li>
                  <li>$1,520,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$3,900,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="associate-general-counsel"
        className="survey-results-section__summary sensitive"
      >
        <h2>Associate General Counsel</h2>
        <table>
          <thead>
            <tr>
              <th>
                <strong>Entire Grouping</strong>
              </th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>ALL AGC</p>
                <p>AGC</p>
                <p>AGC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>110</li>
                  <li>85</li>
                  <li>25</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$125,000</li>
                  <li>$125,000</li>
                  <li>$260,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$300,000</li>
                  <li>$300,000</li>
                  <li>$385,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$420,000</li>
                  <li>$400,000</li>
                  <li>$570,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$580,000</li>
                  <li>$565,000</li>
                  <li>$700,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$725,000</li>
                  <li>$685,000</li>
                  <li>$810,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,100,000</li>
                  <li>$995,000</li>
                  <li>$1,500,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,875,000</li>
                  <li>$2,875,000</li>
                  <li>$1,800,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Experience</strong>
              </th>
              <th>Sample</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>16 Years or More</p>
                <p>15 Years or Less</p>
              </td>
              <td>
                <ul>
                  <li>47</li>
                  <li>63</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>35</li>
                  <li>50</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$240,000</li>
                  <li>$125,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$395,000</li>
                  <li>$265,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$525,000</li>
                  <li>$390,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$700,000</li>
                  <li>$525,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$870,000</li>
                  <li>$670,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,260,000</li>
                  <li>$800,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,875,000</li>
                  <li>$1,800,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Assets</strong>
              </th>
              <th>Sample</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>$20Bn and Over</p>
                <p>$5Bn to {"<"} $20Bn</p>
                <p>Under $5Bn</p>
              </td>
              <td>
                <ul>
                  <li>71</li>
                  <li>29</li>
                  <li>10</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>62</li>
                  <li>17</li>
                  <li>6</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>9</li>
                  <li>12</li>
                  <li>4</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$175,000</li>
                  <li>$180,000</li>
                  <li>$125,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$340,000</li>
                  <li>$260,000</li>
                  <li>$195,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$422,500</li>
                  <li>$450,000</li>
                  <li>$395,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$620,000</li>
                  <li>$580,000</li>
                  <li>$417,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$775,000</li>
                  <li>$680,000</li>
                  <li>$650,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,200,000</li>
                  <li>$850,000</li>
                  <li>$715,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,875,000</li>
                  <li>$1,750,000</li>
                  <li>$850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Type</strong>
              </th>
              <th>Sample</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Hedge</p>
                <p>PE/Venture</p>
                <p>Blended</p>
              </td>
              <td>
                <ul>
                  <li>30</li>
                  <li>15</li>
                  <li>65</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>15</li>
                  <li>10</li>
                  <li>60</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>15</li>
                  <li>5</li>
                  <li>5</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$125,000</li>
                  <li>$205,000</li>
                  <li>$175,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$265,000</li>
                  <li>$285,000</li>
                  <li>$335,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$412,500</li>
                  <li>$528,000</li>
                  <li>$420,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$570,000</li>
                  <li>$715,000</li>
                  <li>$575,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$675,000</li>
                  <li>$985,000</li>
                  <li>$705,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$817,000</li>
                  <li>$1,450,000</li>
                  <li>$1,100,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,875,000</li>
                  <li>$1,750,000</li>
                  <li>$2,100,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Location</strong>
              </th>
              <th>Sample</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>NY Metro/CA</p>
                <p>Elsewhere</p>
              </td>
              <td>
                <ul>
                  <li>87</li>
                  <li>23</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>64</li>
                  <li>21</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>23</li>
                  <li>2</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$175,000</li>
                  <li>$125,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$341,000</li>
                  <li>$245,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$467,500</li>
                  <li>$345,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$600,000</li>
                  <li>$437,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$722,000</li>
                  <li>$717,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,144,000</li>
                  <li>$898,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,875,000</li>
                  <li>$1,750,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="chief-compliance-officer"
        className="survey-results-section__summary sensitive"
      >
        <h2>Chief Compliance Officer</h2>
        <table>
          <thead>
            <tr>
              <th>
                <strong>Entire Grouping</strong>
              </th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All CCO</p>
                <p>CCO</p>
                <p>AGC/CCO</p>
                <p>GC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>166</li>
                  <li>46</li>
                  <li>25</li>
                  <li>95</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$170,000</li>
                  <li>$170,000</li>
                  <li>$260,000</li>
                  <li>$285,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$377,500</li>
                  <li>$310,000</li>
                  <li>$385,000</li>
                  <li>$470,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$526,500</li>
                  <li>$385,000</li>
                  <li>$570,000</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$757,500</li>
                  <li>$605,000</li>
                  <li>$700,000</li>
                  <li>$890,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,200,000</li>
                  <li>$1,011,000</li>
                  <li>$810,000</li>
                  <li>$1,325,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,700,000</li>
                  <li>$1,300,000</li>
                  <li>$1,500,000</li>
                  <li>$1,900,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$3,900,000</li>
                  <li>$1,800,000</li>
                  <li>$4,700,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Experience</strong>
              </th>
              <th>Sample</th>
              <th>CCO</th>
              <th>AGC/CCO</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>16 Years or More</p>
                <p>15 Years or Less</p>
              </td>
              <td>
                <ul>
                  <li>122</li>
                  <li>44</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>36</li>
                  <li>10</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>74</li>
                  <li>21</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$170,000</li>
                  <li>$230,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$391,000</li>
                  <li>$352,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$585,000</li>
                  <li>$475,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$805,000</li>
                  <li>$585,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,245,000</li>
                  <li>$1,030,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,745,000</li>
                  <li>$1,617,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$2,000,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Assets</strong>
              </th>
              <th>Sample</th>
              <th>CCO</th>
              <th>AGC/CCO</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>$20Bn and Over</p>
                <p>$5Bn to {"<"} $20Bn</p>
                <p>Under $5Bn</p>
              </td>
              <td>
                <ul>
                  <li>30</li>
                  <li>51</li>
                  <li>85</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>13</li>
                  <li>9</li>
                  <li>24</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>9</li>
                  <li>12</li>
                  <li>4</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>8</li>
                  <li>30</li>
                  <li>57</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$265,000</li>
                  <li>$260,000</li>
                  <li>$170,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$520,000</li>
                  <li>$500,000</li>
                  <li>$348,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$715,000</li>
                  <li>$615,000</li>
                  <li>$465,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$880,000</li>
                  <li>$950,000</li>
                  <li>$650,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,340,000</li>
                  <li>$1,410,000</li>
                  <li>$900,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,825,000</li>
                  <li>$1,700,000</li>
                  <li>$1,390,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$3,900,000</li>
                  <li>$4,700,000</li>
                  <li>$3,750,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Type</strong>
              </th>
              <th>Sample</th>
              <th>CCO</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Hedge</p>
                <p>PE/Venture</p>
                <p>Blended</p>
              </td>
              <td>
                <ul>
                  <li>83</li>
                  <li>37</li>
                  <li>46</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>18</li>
                  <li>11</li>
                  <li>17</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>15</li>
                  <li>5</li>
                  <li>5</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>50</li>
                  <li>21</li>
                  <li>24</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$170,000</li>
                  <li>$230,000</li>
                  <li>$260,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$350,000</li>
                  <li>$405,000</li>
                  <li>$445,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$524,000</li>
                  <li>$600,000</li>
                  <li>$535,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$700,000</li>
                  <li>$830,000</li>
                  <li>$842,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$980,000</li>
                  <li>$1,625,000</li>
                  <li>$1,300,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,480,000</li>
                  <li>$2,750,000</li>
                  <li>$1,725,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,150,000</li>
                  <li>$4,700,000</li>
                  <li>$3,750,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>
                <strong>By Location</strong>
              </th>
              <th>Sample</th>
              <th>CCO</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>NY Metro/CA</p>
                <p>Elsewhere</p>
              </td>
              <td>
                <ul>
                  <li>135</li>
                  <li>31</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>36</li>
                  <li>10</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>23</li>
                  <li>2</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>76</li>
                  <li>19</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$170,000</li>
                  <li>$230,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$390,000</li>
                  <li>$345,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$550,000</li>
                  <li>$445,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$800,000</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,262,500</li>
                  <li>$992,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,700,000</li>
                  <li>$1,387,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,700,000</li>
                  <li>$3,900,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SurveyResultsSection;
